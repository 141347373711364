@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,900;1,900&family=Open+Sans&display=swap');
@import "~bootstrap/scss/bootstrap";

body.menu-opened {
    .MuiDrawer-paperAnchorDockedLeft {
        width: 195px;
    }
}

a {
    opacity: 1.0;
    color: #4f6bff;
}
a:hover {
    color: inherit;
}

.MuiButton-contained { 
    box-shadow: none!important;
    font-weight: bold!important;
}

.grey-button {
    background-color: #eef1f9!important;
}
.theme-color-dark { 
    .MuiButton-containedPrimary:hover {
        color: #FFF;
    }
    .MuiButton-containedPrimary {
        color: #FFF;
    }
}
.theme-color-light { 
    .MuiButton-containedPrimary {
        color: #000;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}
p {
    margin-block-start: 0;
    margin-block-end: 0;
}
.footer-wrapper {
    position: relative;
}
div#google_translate_element {
    position: absolute;
    text-align: right;
    bottom: 137px;
    z-index: 20000;
    left: 0;
    right: 0;
    text-align: center;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}
.loading-mask {
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    z-index: 999;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .spinner-grow {
        color: #fff;
        margin: 0 5px;
    }
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }   
}
body {
    background: #eef1f9;
    font-family: 'Open Sans';
    font-style: normal;
    /*&.menu-opened {
        overflow-y: hidden;
    }*/
}
.app-wrapper {
    min-height: 100vh;
    position: relative;
}
.content-wrapper {
    min-height: 100vh;
    padding-top: 54px;
    overflow-x: hidden;
    &.hasSubTitle  {
        padding-top: calc(54px + 54px);
        .inner-content-wrapper {
            min-height: calc(100vh - 54px - 203px - 54px);   
        }    
    }
    &.hasFooterSec  {
        .inner-content-wrapper {
            min-height: calc(100vh - 54px - 203px - 320px);   
            @media screen and (max-width: 1000px) {
                min-height: calc(100vh - 54px - 203px - 500px);
            }
            @media screen and (max-width: 420px) {
                min-height: calc(100vh - 54px - 203px - 574px);
            }
        }
        &.hasSubTitle  {
            .inner-content-wrapper {
                min-height: calc(100vh - 54px - 203px - 320px - 54px);   
                @media screen and (max-width: 1000px) {
                    min-height: calc(100vh - 54px - 203px - 500px - 54px);   
                }
                @media screen and (max-width: 420px) {
                    min-height: calc(100vh - 54px - 203px - 574px - 54px);      
                }
            }
        }
    }
}
.inner-content-wrapper {
    /*min-height: calc(100vh - 54px - 203px);*/
    &.hasTwitterHeight {
        @media screen and (min-width: 1080px) {
            min-height: 870px;
        }
    }
}
.hasTwitter  {
    width: calc(100% - 380px);
    @media screen and (max-width: 1080px) {
        width: 100%;
    }
    display: inline-block;
}
.twitter-embeded {
    .twitter-one {
        display: none;
        @media screen and (max-width: 740px) {
            display: block;
            background-color: #fff;
            padding: 0 11px;
            margin-top: 16px;
        }
        .title {
            text-decoration: none;
            display: block;
        }
        iframe {
            border: 1px solid #c9d0d7;
            border-radius: 6px;
            overflow: hidden;
        }
    }
    @media screen and (min-width: 740px) {
        min-height: 870px;
    }
    .twitter-phone {
        padding: 65px 58px 80px 94px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 0%;
        @media screen and (max-width: 1080px) and (min-width: 740px) {
            width: 440px;
            position: absolute;
            right: 20px;
        }
        @media screen and (max-width: 740px) {
            display: none;
        }
    }
    .twitter-block {
        display: none;
    }
    @media screen and (min-width: 1080px) {
        width: 440px;
        position: absolute;
        top:-40px;
        right: -380px;
        display: inline-block;
    }
    @media screen and (max-width: 1080px) and (min-width: 740px) {
        min-height: 730px;
        width: calc(100% - 32px);
        margin: 0 auto;
        position: relative;
        .twitter-block {
            padding: 23px;
            position: absolute;
            display: block;
            background: #595959;
            border-radius: 16px;
            height: 420px;
            top: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: calc(100% - 380px);
                max-height: 200px;
            }
            .twitter-block-title {
                background-repeat: no-repeat;
                background-position: 0 50%;
                background-size: contain;
                font-family: 'Open Sans';
                font-size: 26px;
                font-weight: 500;
                line-height: 38px;
                color: #FFFFFF;
                padding-left: 50px;
                margin-top: 10px;
                @media screen and (max-width: 860px) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }
}
.modal {
    top: 4em;
    &.urgent-message {
        .modal-footer {
            border-top: none;
            padding: 3px 24px 17px;
            .btn {
                color: #40A9FF;
                background-color: #fff;
                border-color: #40A9FF;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
        }
        .modal-header {
            border-bottom: none;
            padding: 17px 24px;
            position: relative;
        }
        .modal-body {
            padding: 0px 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #595959;
        }
        .close-urgent {
            position: absolute;
            width: 16px;
            height: 16px;
            right: 24px;
            top: 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
        }
        .urgent-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #262626;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: 0% 50%;
            padding-left: 40px;
        }
        .modal-content {
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
        }

        .modal-dialog {
            right: 10px;
            top: 10px;
            margin: 0;
            width: 500px;
            max-width: calc(100% - 20px);
            position: absolute;
        }
    }
}

.modal-menu {
    position: fixed;
    left: 0;
    width: 190px;
    /*@media screen and (max-width: 768px) {
        left:300px;
    }*/
    top:0;
    bottom: 0;
    z-index: 999;
    &.menu-closed {
        display: none;
    }
    &.menu-opened {
        display: block;
    }
}
.clear-fix {
    clear: both;
}
.title {
    font-size: 26px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    color: #1890FF;
    padding: 15px 11px;
    position: relative;
    .show-all {
        position: absolute;
        top: 23px;
        right: 11px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #1890FF;
        text-decoration: none;
        text-transform: uppercase;
        @media screen and (max-width: 420px) {
            position: relative;
            display: block;
            right: unset;
            top: unset;
        }
    }
}
.blocks-wrapper {
    overflow: hidden;
    padding: 10px 0;
    &.expand {
        overflow: visible;
        .blocks {
            width: 100% !important;
            .block {
                margin-bottom: 10px;
            }
        }
    }
}
.calendar-wrapper {
    padding: 10px;
    background: #F5F5F5;
}
.calendar-filter-wrapper {
    padding: 10px;
    // background: #C4C4C4;   
}
.view-detail {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    background: #40A9FF;
    padding: 0 16px;
    text-shadow: none;
    margin-top: 5px;
    color: #fff;
    text-decoration: none;
    &:hover {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
}
.carousel {
    .carousel-item {
        color: #fff;
        font-size: 46px;
        line-height: 54px;
        a {
            text-decoration: none;
            color: #fff;
        }
        @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 28px;
        }
        .wrapped {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            margin: 0 auto;
        }
        .wrapped-in {
            width: 100%;
            .school-name {
                text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.55);
            }
            .status {
                font-size: 48px;
                font-weight: 500;
                margin: 0 auto;
                height: 54px;
                position: relative;
                &.lose {
                    font-size: 36px;
                }
                @media screen and (max-width: 768px) {
                    height: 40px;
                }
            }
            img {
                height: 150px;
            }
            .score-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.55);
                width: 650px;
                margin: 0 auto;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                .score-status {
                    width: 140px;
                    text-align: center;
                }
                .score-left, .score-right {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;
                    color: #FFFFFF;
                    text-shadow: none;
                }
                .score-left {
                    width: calc(100% - 290px - 139px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                .score-right {
                    width: 150px;
                }
                .score-detail {
                    width: 109px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 32px;
                    border-radius: 4px;
                    text-align: center;
                    display: inline-block;
                    background: #40A9FF;
                    padding: 0 16px;
                    text-shadow: none;
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        color: #fff;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
        .row {
            width: 100%;
            margin: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.55);
            @media screen and (max-width: 768px) {
                width: 100%;
                .col-8, .col-4, .col-12{
                    padding-right: 5px;
                    padding-left: 5px;
                }
                .view-detail {
                    margin: 5px auto 0;
                    display: block;
                    width: 114px;
                }
            }
            .col-8 {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
.chips {
    text-align: center;
    .badge {
        color: #fff;
        margin: 5px;
        border-radius: 4px;
        padding: 5px;
        opacity: 0.5;
        cursor: pointer;
        &.selected {
        opacity: 1;
        }
        &.chip-1 {
            background: #508C46;
        }
        &.chip-2 {
            background: #5F6BBA;
        }
        &.chip-3 {
            background: #F4B63F;
        }
        &.chip-4 {
            background: #A82E26;
        }
        &.chip-5 {
            background: #E25D32;
        }
        &.chip-6 {
            background: #9E9D3D;
        }
        &.chip-7 {
            background: #39818D;
        }
        &.chip-8 {
            background: #abb2b9;
        }
    }
}

.detail-block-responsive {
    width: 98%;
    padding: 22px;
    margin: 27px auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    color: #000000;
    .detail-block-title {
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        &.has-icon {
            position: relative;
        }
    }
}

.detail-block {
    width: 960px;
    padding: 22px;
    @media screen and (max-width: 1004px) {
        width: calc(100% - 10px);
    }
    margin: 27px auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    color: #000000;
    .detail-block-title {
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        &.has-icon {
            position: relative;
        }
    }
    .detail-block-date {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 5px 0;
    }
    &.mobile-full {
        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            overflow-x: auto;
        }
    }
    table {
        width: 100%;
        thead tr {
            &:first-child {
                th {
                    font-family: "Exo";
                    text-transform: uppercase;
                    color: #fff  !important;
                    font-size: 14px;
                    font-weight: 400;
                    border: none;
                    * {
                       color: #fff  !important;
 
                    }
                }
            }
        }
    }
}
.scroll-container, .news-container {
    .block {
        background-color: #fff;
        width: 300px;
        border-radius: 4px;
        padding: 4px 6px;
        box-sizing: border-box;
        box-shadow: 0 0 6px rgba(0,0,0,0.1);
        overflow:hidden;
        text-decoration: none;
        margin-left: 10px;
        .sport-name {            
            color: #000000;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .opponent {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: #8C8C8C;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .has-activity {
            position: relative;
        }
        .activity-type {
            position: absolute;
            line-height: 24px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            &.yellow {
                color: #262626;
                background: #FFF566;
            }
            &.red {
                background: #EB5757;
                color: #FAFAFA;
            }
            .first-letter {
                width: 24px;
            }
            .all {
                display: none;
            }
            &:hover {
                .first-letter {
                    display: none;
                }
                .all {
                    display: inline-block;
                    padding: 0 8px;
                    text-align: left;
                }
            }
        }
        .day-month {
            &.gs-h {
                background: #3974CB;
            }

            &.gs-a {
                background: #A82E26;
            }

            &.gs-m {
                background: black;
            }
            &.gs-p,
            &.gs-g,
            &.gs-b,
            &.gs-c, 
            &.gs-n {
                background: #595959;
            }
            
            color: #fff;
            padding: 5px;
            .day {
                font-size: 36px;
                font-weight: 400;
                line-height: 42px;
            }
            .month {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
            }
        }
        .name-ago {
            display: flex;
            flex-direction: row;
            color: #595959;
            min-height: 16px;
            margin-bottom: 6px;
            .flex {
                flex: 1
            }
            .name {
                font-size: 16px;
                font-weight: bold;
                line-height: 16px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 16px;
            }
            .ago {
                margin-top: 5px;
                width: 90px;
                text-align: center;
                font-size: 10px;
                font-weight: 300;
                line-height: 16px;
                background: #333333;
                border-radius: 2px;
                color: #fff;

            }
        }
        .desc {
            height: 84px;
            overflow:hidden;
            * {
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 14px !important;
                color: #595959 !important;
                font-family: 'Open Sans' !important;
                font-style: normal !important;
            }
            p {
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: #595959;
            }
        }
    }
}
.news-container {
    margin-top: 20px;
    .block{
        width: calc(100% - 15px);
        margin: 0 auto;
        max-width: 937px;
        display: block;
        margin-bottom: 11px;
        .date {
            text-decoration: none;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #595959;
            margin: 4px 0;
        }
    }
}
.scroll-container {
    height: 129px;
    width: 100%;
    &.announcements {
        height: 170px;
    }
    &.events {
        .block {
            padding: 0;
            width: 120px;
            text-align: center;
            .desc {
                height: 41px;
                padding: 10px 10px 0;
            }
        }
    }
    &.roster {
        .block {
            .day-month {
                background: #64489D;
            }
            .desc {
                height: 52px;
                .sport-name, .opponent {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    line-height: 16px !important;
                }
            }
        }
    }
    &.photos {
        .block {
            padding: 0;
            width: 160px;
            height: 110px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }
    .scroll-container-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.close-fullscreen-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    background-size: auto 28px;
    z-index: 999;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.menu-opened + .content-wrapper {
    .fullScreenGallery {
        left:190px;
        @media screen and (max-width: 768px) {
            left:300px;
        }
    }    
}
.fullScreenGallery {
    position: fixed;
    left:0;
    right: 0;
    top:0;
    bottom: 0;
    background-color: rgba(0,0,0,0.85);
    z-index: 2;
    .carousel-control-prev, .carousel-control-next {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    .carousel-inner {
        height: 100%;
        .carousel-item {
            text-align: center;
            height: 100%;
            .wrapped {
                position: relative;
                @media screen and (max-width: 768px) {
                    text-align: center;
                    img {
                        width: calc(100% - 20px);
                        border-radius: 4px;
                    }
                }
                height: 100%;
                img {
                    border-radius: 4px;
                }
                span {
                    position: relative;
                }
                .caption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    background: rgba(255, 255, 255, 0.85);
                    line-height: 42px;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #000;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    border-radius: 0 0 4px 4px;
                    padding: 0 9px;
                }
            }
        }
    }
    img {
        margin: 0 auto;
    }
}
.table-header {
    background-color: #C4C4C4;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #262626;
    line-height: 48px;
}
.table-row {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #595959;
    min-height: 30px;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #595959;
        text-decoration: none;
    }
}
.schedule-events-body .table-row {
    font-size: 14px;
}
.table-header, .table-row {
    .jersey, 
    .date {
        width: 160px;
        margin: 0 5px;
    }
    .jersey {
        text-align: center;
    }
    .status {
        width: 20px;
        margin: 0 5px 0 18px;   
    }
    .ha-header {
        width: 65px;
        margin: 0 10px;
        text-transform: uppercase;
    }
    .ha {
        width: 65px;
        margin: 0 10px;
        text-transform: lowercase;
    }

    .ha::first-letter {
        text-transform: uppercase;
    }

    .day {
        width: 30px;
        margin: 0 5px;   
    }
    .time {
        width: 65px;
        margin: 0 5px;   
    }
    .result {
        width: 50px;
        margin: 0 10px;
        color: #000;
        &.status-l {
            color: #EB2F96;
        }
        &.status-w {
            color: #52C41A;
        }
        &.status-t {
            color: #262626;
        }
    }
}
.gallery {
    width: 930px;
    margin: 15px auto;
    @media screen and (max-width: 960px) {
        width: calc(100% - 20px);
    }
    .block {
        width: 300px;
        @media screen and (max-width: 960px) {
            width: calc(50% - 8px);
        }
        @media screen and (max-width: 576px) {
            width: 100%
        }
        height: 200px;
        display: inline-block;
        background-size: contain;
        background-color: #C4C4C4;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: relative;
        margin: 0 0 15px 0;
        &:nth-child(3n+2) {
            margin: 0 15px 15px;
        }
        @media screen and (max-width: 960px) {
            &:nth-child(3n+2) {
                margin: 0;
            }
            &:nth-child(even) {
                margin: 0 0px 15px 15px;
            }
        }
        @media screen and (max-width: 576px) {
            &:nth-child(3n+2) {
                margin: 0 0 15px;
            }
            &:nth-child(even) {
                margin: 0 0 15px;
            }
        }
        .caption {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 300px;
            @media screen and (max-width: 960px) {
                width: 100%;
            }
            background: rgba(51, 51, 51, 0.85);
            line-height: 42px;
            font-size: 14px;
            font-weight: 400;
            text-align: right;
            color: #F5F5F5;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 0 0 4px 4px;
            padding: 0 9px;
        }
    }
}
.mobile-select {
    background: #EFEFEF;
    padding: 11px 10px 0 10px;
    margin-bottom: -17px;
    .col-12 {
        padding: 0 12px;
    }
}
.detail-block-title-mobile {
    font-size: 26px;
    font-weight: 400;
    line-height: 38px;
    color: #595959;
    &.has-margin {
        margin-bottom: -20px;
    }
}

.footer-link-wrapper {
    height: 250px;
    display: block;
    position: relative;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    &:after {
        position: absolute;
        left: 5%;
        content: '';
        top: -50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 235px;
        height: 300px;
    }
    .bg-line {
        height: 200px;
        margin-top: 50px;
        background-color: #BAE7FF;
    }
    .link-content-wrapper {
        width: 390px;
        margin: 0 auto;
        text-align: center;
        .title-footer {
            font-size: 38px;
            font-weight: 400;
            line-height: 46px;
            color: #000000;
            padding-top: 25px;
        }
        .sub-title-footer {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #595959;
            margin-bottom: 20px;

        }
    }
    @media screen and (max-width: 1000px) {
        height: 470px;
        &:after {
            left: calc(50% - 117px);
            top: 90px;
        }
        .bg-line {
            margin-top: 10px;
            height: 450px;
        }
        .link-content-wrapper {
            .sub-title-footer {
                margin-bottom: 270px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        height: 530px;
        .bg-line {
            margin-top: 10px;
            height: 520px;
        }
    }
}
.apstore-btn, .playmarket-btn {
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 193px;
    @media screen and (max-width: 420px) {
        flex: 0 0 100%;
        max-width: 100%;
        background-position: 50% 50%;
        margin-bottom: 20px;
    }
}
.intro-text {
    background: #C4C4C4;
    padding: 27px 0;
    position: relative;
    .detail-block {
        margin: 0 auto;
        max-height: 300px;
        transition: max-height 1s ease-out;
        overflow: hidden;
        padding: 22px 22px 40px 22px;
    }
    .expand-icon {
        position: absolute;
        bottom: 25px;
        margin: 0 auto;
        background-color: #fff;
        background-size: 18px auto;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        left: 0;
        right: 0;
        height: 40px;
        width: 960px;
        @media screen and (max-width: 1004px) {
            width: calc(100% - 10px);
        }
    }
    .intro-title {
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        color: #000000;
    }
    &.expand {
        .detail-block {
            max-height: 2000px;
            transition: max-height 1s ease-in;
        }
    }
}
.empty-text {
    background: #F5F5F5;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    color: #8C8C8C;
    margin: 0 11px;
    text-align: center;
    line-height: 34px;
    &.no-content {
        margin-top: 20px;
    }
}
.height-20 {
    height: 20px;
}
.team-resource {
    padding-left: 40px;
    position: relative;
    &:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 10px;
        border: 2px solid #1890FF;
        top: calc(50% - 4px);
        left: 19px;
        position: absolute;
    }
    a {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #595959;
        text-decoration: none;
        text-transform: capitalize;
        &:hover {
            text-decoration:none;
        }
    }
}
.links {
    margin: 0;
    .col-xs-12, .col-sm-6 {
        color: #000;
        padding: 5px;
        a {
            color: #000;
            text-decoration: none;
        }
        background-color: #fff;
        &:nth-child(2n) {
            background-color: #f5f5f5;
        }
    }
}
.directions {
    border: 1px solid #E8E8E8;
    margin: 0;
    .link {
        color: #000;
        background-repeat: no-repeat;
        background-position: 98% 50%;
        border-bottom: 1px solid #E8E8E8;
        padding: 0;
        a {
            color: #000;
            text-decoration: none;
            display: block;
            padding: 16px;
        }
        background-color: #fff;
    }
}
.directions-header {
    background: #595959;
    line-height: 54px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0 16px;
    margin: 0;
    .col-12 {
        padding: 0;
    }
}

.subheader-wrapper {
    top:54px;
    background: #fff;
    height: 54px;
    color: #262626;
    position: relative;
    padding-left: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .home-icon {
        width: 44px;
        height: 54px;
        outline: none;
        border:none;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50% 50%;
        position: absolute;
        left: 0;
        top: 0;
    }
    
    span {
        text-transform: capitalize;
        line-height: 54px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 10px;
        font-size: 14px;
        font-weight: 400;
        &:before {
            content: '/';
            padding-right: 10px;
        }
        &:last-child {
            color: #595959;
        }
    }
}
.print-icon-titled {
    width: 44px;
    height: 38px;
    outline: none;
    border:none;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: 50% 50%;
    position: absolute;
    right: 0;
    top:0;
}
.header-wrapper {
    top:0;
    background: #fff;
    // opacity: 0.85;
    border-bottom: 2px solid #EFEFEF;
    height: 54px;
    line-height: 54px;
    color: #595959;
    position: relative;
    padding-left: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .menu-icon {
        width: 44px;
        height: 54px;
        outline: none;
        border:none;
        background-size: 18px 12px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50% 50%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .back-icon {
        width: 44px;
        height: 54px;
        outline: none;
        border:none;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50% 50%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .print-icon {
        width: 44px;
        height: 54px;
        outline: none;
        border:none;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50% 50%;
    }
    .header-title {
        text-transform: capitalize;
        color: #595959;
        line-height: 54px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .menu-right-icons {
        position: absolute;
        right: 0;
        top:5px;
        .call-icon, .map-icon, .facebook-icon, .print-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            z-index: 2;
            margin-right: 20px;
        }
    }
}
.subheader-wrapper, 
.header-wrapper {
    position: fixed;
    right: 0;
    z-index: 1;
    left:0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.content-wrapper {
    &.hasSubTitle  {
        .header-wrapper {
            box-shadow: none;
        }
    }
}
.menu-opened {
    +.content-wrapper   {
        margin-left: 190px;
        .header-wrapper, .subheader-wrapper {
            left: 190px;
        }
        @media screen and (max-width: 740px) {
            margin-left: 300px;
            .header-wrapper, .subheader-wrapper {
                left: 300px;
            }
            .menu-right-icons {
                display: none;
            }
        }
    }
}
@media screen and (min-width: 1280px) {
    /*.modal-menu {
        &.menu-closed,
        &.menu-opened {
            z-index: 1;
            position: fixed;
            display: block;
            width: 300px;
            .menu-content, .menu-content-inner {
                box-shadow: none;
            }
        }
    }
    .header-wrapper {
        padding-left: 14px;
        &.has-back {
            padding-left: 44px;
        }
    }
    .menu-close-icon, .menu-icon {
        display: none;
    }
    .content-wrapper {
        margin-left: 300px;
        &.not-found {
            margin-left: 0px;
            .header-wrapper {
                left:0px;
            }
        }
    }
    .subheader-wrapper, 
    .header-wrapper {
        left:300px;
    }*/
}
.error {
    padding: 16px;
    .title-error {
        font-size: 72px;
        font-weight: 500;
        line-height: 84px;
    }
    .sub-title-error {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
    }
    .error-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .back-icon,
        .refresh-icon {
            width: 60px;
            height: 60px;
            margin: 0 10px;
            cursor: pointer;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }
    .robot {
        margin-top: -80px;
    }
}
.not-found-block, .robot {
    height: 353px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.not-found-block {
    margin-top: 50px;
    background-repeat: repeat-x;
}
.clear-btn {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #595959;
    width: 90px;
    margin: 10px auto 0;
    cursor: pointer;
}
.calendar-mobile {
    margin-top: 20px;
    .table-row {
        background-color: #fff;
        .status {
            display: none;
        }
    }
    .table-header {
        .status {
            display: none;
        }
    }
}
.blue-card {
    background: #E6F7FF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 14px 23px;
    margin: 0 11px 15px 11px;
    width: 100%;
    .detail-block-title {
        color: #000000;
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
    }
    .detail-block-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        color: #595959;
    }
}

.photos-carousel {
    width: calc(100% - 22px);
    margin: 20px auto;
    .carousel-item {
        .wrapped {
            height: 48vh;
            min-height: 480px;
            @media screen and (max-width: 1080px) and (min-width: 740px) {
                height: 33vh;
                min-height: 250px;
            }
            @media screen and (max-width: 740px) {
                height: 25vh;
                min-height: 208px;
            }
            border-radius: 8px;
            overflow: hidden;
            img {
                width: 90%;
                height: auto;
            }
            .block {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                position: relative;
                .caption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(0,0,0,0.30);
                    color: #fff;
                    text-align: center;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 28px;
                    line-height: 30px;
                    padding: 23px;
                }
            }
        }
    }
    .carousel-control-prev, .carousel-control-next {
        width: 30px;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        // background-color: #FFFFFF !important;
        background-image: '';
    }

    body * {
      visibility: hidden;
    }

    .hidden-header {
        display: block;
    }

    .section-header {
        color: white;
    }
  
    #section-to-print, 
    #section-to-print * {
      visibility: visible;
    }
  
    #section-to-print {
      .no-print {
        display: none !important;
        visibility: hidden;
      }
      .print-only {
        visibility: visible;
        display: inherit;
      }
    }
  
    .no-print {
        display: none !important;
    }
  
    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
      width: 75%
    }
  
    .pagebreak { page-break-after: always; }

    .table-header {
        background: #000000 !important;
        color: #FFFFFF !important;
        &.print-alt {
            background: #CFCFCF !important;
            .section-header {
                color: #000000 !important;
            }
        }
    }

    .table thead th {
        background: #000000 !important;
        color: #FFFFFF !important;
    }

    .table-row div {
        color: #000000;
        font-size: 20px;
        line-height: 32px;
    }

    .table-header .section-header {
        font-size: 24px;
    }

    .table-header .date {
        width: 95px;
    }
    .table-header .day {
        width: 50px;
    }

    .schedule-events-body {
        .table-row .date {
            width: 110px;
        }
        .table-row .day {
            width: 50px;
        }
        .table-row div {
            font-size: 14px;
        }
    }



  }


// @media print
// {    
//     .no-print
//     {
//         display: none !important;
//     }
//     .for-print
//     {
//         display: block !important;
//     }
// }

.leader-boards {
    padding: 10px 0 18px 0;
    div {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
    }
}

.ads {
    .adunitContainer {
        padding: 10px;
        border: 5px solid blue;
        width: auto;
        clear: both;
        text-align: center;
    }
    .top {
        display: block;
        width: 100%;
        text-align: center;

    }

    .footer {
        display: block;
        width: 100%;
    }

    .desktop {
        background-color: red;
    }

    .tablet {
        background-color: green;
    }

    .mobile {
        background-color: yellow;
    }

    /* 
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

    @media (min-width: 1281px) {

        /* CSS */
        .mobile,
        .tablet {
            display: none;
        }


    }

    /* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

    @media (min-width: 1025px) and (max-width: 1280px) {

        /* CSS */
        .mobile,
        .tablet {
            display: none;
        }

    }

    /* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

    @media (min-width: 768px) and (max-width: 1024px) {

        /* CSS */
        .mobile,
        .desktop {
            display: none;
        }

    }

    /* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

        /* CSS */
        .desktop,
        .mobile {
            display: none;
        }

    }

    /* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

    @media (min-width: 481px) and (max-width: 767px) {

        /* CSS */
        .desktop,
        .tablet {
            display: none;

        }

    }

    /* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

    @media (min-width: 320px) and (max-width: 480px) {

        /* CSS */
        .desktop,
        .tablet {
            display: none;
        }

    }
}
.Section1 {
    overflow-x: auto;
}

/***********************************
 ***********************************
 * 2021-V3-Updates
 ***********************************
 ***********************************/

body .container-bg-dk,
body .container-bg-dk .MuiListSubheader-root,
body .container-bg-dk .MuiListItem-root,
body .container-bg-dk .MuiListItem-root a,
body .container-bg-dk .MuiListItemIcon-root {
    color: #FFFFFF !important;
}
body .container-bg-lt,
body .container-bg-lt .MuiListSubheader-root,
body .container-bg-lt .MuiListItem-root,
body .container-bg-lt .MuiListItem-root a,
body .container-bg-lt .MuiListItemIcon-root {
    color: #000000 !important;
}

.component-main-root {
    display: flex;
}
.component-main-content {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    background-color: #eef1f9;
}

.MuiListItem-root {
    padding-top: 0;
    padding-bottom: 2px;
}

body .MuiListSubheader-root {
    font-weight: 700;
    font-size: 16px;
}
body .MuiPopover-paper {
    max-height: calc(100% - 32px - 64px);
    background-color: transparent;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    .MuiBox-root {
        padding: 6px 5px;
    }
    ul.MuiList-nosubheader li {
        display: none;
    }
    ul.MuiList-subheader {
        padding: 0 14px;
        /*columns: 2;
        column: auto;*/
        div.MuiListSubheader-root{
            font-family: Exo;
            text-transform: uppercase;
            position: relative;
            padding: 0px;
            line-height: 42px;
            margin-bottom: 5px;
        }
        li.MuiListItem-gutters {
            text-transform: capitalize;
            padding: 2px 0px 2px 0px;
        }
        li.MuiListItem-gutters a {
            display: block;
            width: 100%;
        }
    }
}
#component-breadcrumb-levels-popover .MuiPopover-paper {
    background:#222222;
    a {
        color: #FFFFFF;
    }
    .active {
        background-color: #444444;
        border-radius: 4px;
        padding: 0 10px;
    }
}
@media screen and (max-width: 600px) {
    li.MuiBreadcrumbs-li:nth-of-type(2),
    li.MuiBreadcrumbs-li:nth-of-type(3),
    li.MuiBreadcrumbs-li:nth-of-type(4),
    li.MuiBreadcrumbs-separator:nth-of-type(1),
    li.MuiBreadcrumbs-separator:nth-of-type(2),
    li.MuiBreadcrumbs-separator:nth-of-type(3) {
        display: none !important;
    }
    .breadcrumbs-bar {
        margin-top: 11px !important;
    }
}

.MuiBreadcrumbs-li a p {
    color: #000000;
    text-decoration: underline;
}
.breadcrumb-popover-levels span {
    color: #FFFFFF;
    cursor: pointer;
}

body.theme-color-dark {
    #header-largeToolbar-container, #header-largeToolbar-container a {
        color: #FFFFFF;
    }
    .MuiListSubheader-root {
        border-bottom: 1px solid rgba(255,255,255,.35)
    }
}
body.theme-color-light {
    #header-largeToolbar-container, #header-largeToolbar-container a {
        color: #000000;
    }
    .MuiListSubheader-root{
        border-bottom: 1px solid rgba(0,0,0,.35)
    }
}

body .news-card-content-container .MuiPaper-elevation1 {
    border: 2px solid #E3E3E3;
    box-shadow: none;
}

body .news-card-content-header {
    padding-bottom: 0;
}

/* Upcoming Events */
.react-multi-carousel-list.component-events {
    div[class^='makeStyles-itemType'] p, 
    div[class^='makeStyles-directionsContainer-'] p,
    li div:nth-child(2) p {
        font-size: 1.1em;
        font-weight: 700;
        line-height: 1.4em;
    }
    p {
        font-size: .975em;
        line-height: 1.2em;
        padding: 0 2px;
        letter-spacing: 0;
    }
}
 
 .component-section-header a {
     color: #000000;
 }

.component-main-container {
    display: flex;
    min-height: 1100px;
    padding-left: 0;
    padding-right: 12px;
    padding-bottom: 32px;
    background-color: #FFFFFF;
}

#header-smallToolbar-toolbar {
    display: none;
    
}

#header-largeToolbar-container {
    a {
        color: #FFFFFF;
    }
}

@media screen and (max-width: 600px) {
    #header-smallToolbar-toolbar {
        justify-content: space-between;
        padding-right: 0;
        display: flex;
        padding-left: 4px !important;
    }
}

/*
* Main Component
* Small – Phone (Landscape): 0 – 600px
*/
@media screen and (max-width: 600px) {
.component-main-container {
    min-height: auto;
}
}


body header.MuiAppBar-positionAbsolute {
    position: fixed;
    .MuiContainer-root {
        padding-left: 20px;
        padding-right: 4px;
    }
}
body #root main {
    height: auto;
    overflow-x: hidden;
}
body .breadcrumbs-bar {
    padding: 10px 5px 10px 10px;
    margin-left: 0px;
    font-size: 1em;
    background-color: #DDD;
    border-radius: 4px;
    margin-top: 10px;
    a, h6 {
        font-size: 16px;
    }
}

.component-header-carousel {
    margin-top: 20px;
}

.component-header-carousel-card {
    padding-bottom: 40px;
    padding-top: 10px;
    .component-header-carousel-card-caption {
        background-color: #eef1f9;
        margin-top: 0;
        padding: 10px;
    }
}

.react-multiple-carousel__arrow {
    border-radius: 4px;
    background: #000000;
}
.react-multiple-carousel__arrow--left {
    left: 0px;
}
.react-multiple-carousel__arrow--right {
    right: 0px; 
}

.react-multi-carousel-dot button {
    background: transparent;
    border-color: #000000;
}

.react-multi-carousel-dot--active button {
    background: #000000;
}


body .component-results-wrapper {
    box-shadow: none;
    .react-multi-carousel-list.component-results {
        width: calc(100% - 70px);
        align-self: center;
        ul.react-multi-carousel-track {
            align-items: center;
            a {
                padding: 8px 10px;
            }
            a div.MuiBox-root {
                text-align: right;
                h6 {
                    line-height: 1em;
                    margin: 2px 0;      
                }
                h6:last-child {
                    color: #757575;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            a div.MuiBox-root:last-child {
                text-align: left;
            }
        }
    }
    .carousel-button-group {
        /*position: absolute;*/
        width: 100%;
        height: calc(100% - 10px);
        display: flex;
        align-items: center;
        .react-multiple-carousel__arrow {
            min-width: 34px;
            height: 100%;
            top: 0;
            background-color: white;
        }
        .react-multiple-carousel__arrow::before {
            color: black;
        }

        // .react-multiple-carousel__arrow--left .react-multiple-carousel__arrow--right {
        //     background-color: red;
        // }
    }   
}


body.page_home .MuiFormControl-root.MuiFormControl-fullWidth {
    width: auto;
    margin-left: 10px;
}

 
body {
    .text-superscript {
        vertical-align: super;
        font-size: 50%;
        margin-left: 2px;
    }
    
}

.news-card-content-container {
    .news-card-content {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        &.MuiTypography-root a {
            color: blue !important;
        }
    }
    &.expanded .news-card-content {
        display: inline;
    }
}

.about-container {
    .about-content {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        &.MuiTypography-root a {
            color: blue !important;
        }
        ul li {
            white-space: normal !important;
        }
    }
    &.expanded .about-content {
        display: inline;
    }
}

/*
 * News and Announcements pop-up / AutoRotatingCarousel
 */
 
#component-urgent-announcements {
    #urgent-announcements-title {
        background: #000000;
        h2 {
            display: flex;
            flex: 0 0 auto;
            justify-content: space-between;
        }
        button {
            height: 50px;
            width: 50px;
            margin: -15px 0 0 -25px;
        }
        #urgent-announcements-count {
            color: #777777;
            font-size: 14px;
            flex-wrap: nowrap;
            white-space: nowrap;
            margin-left: 14px;
            margin-right: -10px;
        }
    }
    #urgent-announcements-banner-logo {
            max-height: 120px;
            max-width:100%;
    }
    #urgent-announcements-header {
        font-size: 1.4rem;
        text-align: center;
        font-family: 'Exo';
        text-transform: uppercase;
        margin-top: 10px;
        margin-right: 20px;
        margin-left: 20px;
    }
    #urgent-announcements-sub-header {
        font-family: Open Sans;
        font-size: 1.1rem;
        text-align: center;
        margin-bottom: 10px;
        color: #000;
    }
    .MuiDialogContent-root {
        font-family: Open Sans !important;
        font-size: 1em;
        color: #000;
        background-color: transparent !important;
        &.ad-unit {
            text-align: center;
        }
        #urgent-announcement-announcement-title {
            font-family: Open Sans !important;
            font-weight: 700;
            text-align: center!important;
            font-size: 1.3rem !important;
            background-color: transparent !important;
            margin-top: .25em;
        }
        p, p * {
            font-family: Open Sans !important;
            font-size: 1rem!important;
            margin-bottom: 1em!important;
            margin-block-end: 1em!important;
            background-color: transparent !important;
        }
    } 
    #urgent-announcement-announcement-date {
        text-align: center;
        margin-top: -10px;
        font-style: italic;
    }

    #urgent-announcements-actions {
        background: #000000;
        button span.MuiButton-label {
            color: #FFFFFF;
        }
    }


    .MuiDialogActions-root {
        justify-content: space-between;
    }
}

.theme-color-dark #component-urgent-announcements #urgent-announcements-actions button span.MuiButton-label,
.theme-color-dark #component-urgent-announcements #urgent-announcements-title,
.theme-color-dark #component-urgent-announcements #urgent-announcements-title .MuiIconButton-root,
.theme-color-dark #component-urgent-announcements #urgent-announcements-title #urgent-announcements-count {
    color: #FFFFFF !important;
}
.theme-color-light #component-urgent-announcements #urgent-announcements-actions button span.MuiButton-label,
.theme-color-light #component-urgent-announcements #urgent-announcements-title,
.theme-color-light #component-urgent-announcements #urgent-announcements-title .MuiIconButton-root,
.theme-color-light #component-urgent-announcements #urgent-announcements-title #urgent-announcements-count {
    color: #000000 !important;
}

 body .component-urgent-announcements-modal {
    div[class^='AutoRotatingCarousel-slide-'] {
        background-color: #FFFFFF;
    }
    div[class^='Slide-mediaBackground'] {
        max-height: 150px;
        padding: 20px; 
        border-bottom: 5px solid #FF001F;
    }
    div[class^='Slide-text'] * {
        font-family: Open Sans;
        background-color: transparent !important;
        /*white-space: pre-line;*/
    }
    div[class^='Slide-media-'] img {
        max-height: 100%;
        max-width: 100%;
    }
    button {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        color: rgba(0, 0, 0, 0.87);
        padding: 6px 16px;
        font-size: 0.875rem;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    }
}

/*
 * STAFF PAGE
 */
body.page_static_staff {
    table * {
        font-family: Open Sans !important;
    }
    table * strong {
        font-family: Exo !important;
    }
    
}

/*
 * Header Bars
 */
body .app-bar-spacer {
    width: 100%;
    min-height: 24px;

    @media only screen and (min-width: 1px) and (max-width: 600px)  {
        min-height: 94px;
    }
}

#header-school-banner-container-wrapper {
    margin-top: 30px;
    padding: 0px;
    background: #eef1f9;

    #header-school-logo {
        width: 130px;
        height: 90px;
        padding: 10px;
        object-fit: contain;
    }
}

#header-school-logo-container {
    align-items: center;
}

@media screen and (max-width: 1200px) {
    #header-school-logo {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 960px) {
    #header-school-logo-container {
        text-align: center;
        #header-school-logo {
            margin-left: 0;
        }
    }
}

 /*
  * Header Bars: Tablet Width
  */
 @media screen and (max-width: 979px) { 
     body .app-bar-spacer {
         width: 100%;
     }
 }

 /*********************************************************
  * Ads
  *********************************************************/

 .interstitial-ad-image {
    max-width: 100%;
 }
 #interstitial-ad-title .MuiTypography-body1 {
     margin-top: 15px;
     float: right;
     font-weight: bold;
 }
 .ads.leaderboard_top-leaderboard {
     padding-top: 6px;
 }
 .ad-leaderboard {
    top: 15px;
    min-height: 90px;
 }

 .ad-rail {
    min-height: 250px;
    line-height: 250px;
}

.ad-leaderboard, 
.ad-rail {
   margin: 0 auto;
   position: relative;
}

.show-ad-containers {
   .ad-leaderboard,
   .ad-rail {
       background: #EFEFEF;
   }
}

 /*
  * Small
  */
 @media screen and (max-width: 1049px) { 
    .ad-leaderboard {
        min-height: 50px;
        line-height: 50px;
    }
 }

 /*
  * Medium
  */
 @media screen and (min-width: 1050px) and (max-width: 1289px) { 
    .ad-leaderboard {
        max-width: 728px;
        min-height: 90px;
        line-height: 90px;
    }
 }
 /*
  * Large
  */
 @media screen and (min-width: 1290px) { 
    .ad-leaderboard {
        max-width: 970px;
        min-height: 90px;
        line-height: 90px;
    }
 }
 
 /*
  * School Banner Header: Tablet Width
  */
  @media screen and (max-width: 979px) { 
     .header-school-banner {
         height: 120px;
         max-height: 120px;
         text-align: center; 
         /*width: calc(100% + 24px);*/
         padding: 10px 0;
 
         img {
             height: auto;
             width: auto;
             max-height: 155px;
             max-width: 100%;    
         }
     }
 }
 /*
  * Menu Bar Styles 
  * Small – Phone (Landscape): 0 – 600px
  */
  @media screen and (max-width: 600px) {
    // body .app-bar-spacer {
    //     min-height: 105px;
    // }
    body.has-ads #header-school-banner-container-wrapper{
        height: auto;
        padding-bottom: 20px;
    }
    #header-school-banner-container-wrapper {
        margin-top: 15px;
        
        .header-school-banner-container {
            height: auto;
            padding: 0px;
        }
    }
     .ads.leaderboard_top-leaderboard {
         padding-top:60px;
     }
 }
 
 /*
  * Menu Bar Styles
  */
 body {
 
     .menu-bar {
         margin-bottom: 0;
         flex: 0 0 auto;
 
         .list-item-icon {
             font-size: 1.6em;
         }
 
         .list-item-text {
             text-transform: uppercase;
         }
 
         .list-item-link {
             text-transform: capitalize;
         }

         .MuiListItem-button {
             padding-bottom: 10px;
             padding-top: 10px;
         }
 
     }
     
     .menu-bar .menu-bar-inner {
         transition: width 195ms;
         z-index: 2;
         overflow-x: hidden;
         position: relative;
         white-space: nowrap;
         border-right: 1px solid rgba(0, 0, 0, 0.12);
         left: 0;
         right: auto;
         top: 0;
         flex: 1 0 auto;
         height: 100%;
         display: flex;
         outline: 0;
         overflow-y: auto;
         flex-direction: column;
         box-shadow: none;
         .toggle-icon-down,
         .toggle-icon-up {
             display: none;
         }
     }
     
     .menu-bar .menu-bar-inner ul {
         height: 100%;
         display: flex;
         flex-direction: column;
     }
     
     .menu-bar .menu-bar-inner .MuiListItem-root.MuiListItem-gutters {
         height: auto;
     }
     
     &.menu-opened .menu-bar .menu-bar-inner {
         width: 195px;
     }
 
      /*
       * Menu Bar Opened
       */
     &.menu-opened {
         .menu-bar-inner,
         .MuiDrawer-paperAnchorDockedLeft {
             width: 195px;
             transition: width 195ms;
             z-index: 2;
         }
         .MuiGrid-container.MuiGrid-spacing-xs-3 {
             width: calc(100% - 185px);
             transition: width 195ms
         }
     }
      /*
       * Menu Bar Closed
       */
     .menu-bar {
         margin-bottom: -32px;
     }
     .MuiGrid-container.MuiGrid-spacing-xs-3 {
         width: calc(100% - 50px);
         margin-left: -10px;
         transition: width 195ms;
         position: relative;
         .MuiGrid-grid-md-9 {
            max-width: calc( 100% - 324px );
            transition: width 195ms;
         }
         .MuiGrid-grid-md-3 {
            width: 324px;
            min-width: 324px;
            max-width: 324px;
            transition: 195ms;
            right: 0px;
         }
     }
     .menu-bar-inner {
         width: 60px;
         ul {
             height: 100%;
             display: flex;
             flex-direction: column;
         }
         .mobile-menu-parent {
             width: 100%;
             display: flex;
             align-items: center;
         }  
         .mobile-menu-parent-icon {
             align-items: center;
         }     
         .mobile-menu-parent-text {
             align-items: center;
             margin-bottom: 0px;
         }     
         .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
             flex-grow: 0;
         }
         .MuiListItem-root.MuiListItem-gutters {
             flex-grow: 1;
             height: auto;
         }
         .menu-bar-companyIcon.MuiListItem-gutters.menu-bar-companyIcon {
             padding: 6px 10px;
             flex-grow: 0;

             img {
                width: 150px;
                padding-left: 25px;
             }
         }
     }
 }
 
.menu-container .MuiListItemIcon-root {
    min-width: 43px;
}

@media screen and (min-width: 979px) {
    .menu-container .MuiTypography-root {
        max-width: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
 
 /*
  * Menu Bar Styles 
  * Medium – Tablet (Landscape): 768px – 979px
  */
 @media screen and (max-width: 979px) {
 
     .menu-bar {
         display: none;
     }
     body.menu-opened .menu-bar {
         display: block;
         .menu-bar-inner,
         .MuiDrawer-paperAnchorDockedLeft {
             width: 100%;
             position: absolute;
             top: 55px;
         }
         .mobile-menu-options {
             display: block;
         }
     }
 
     body.menu-opened .menu-bar .menu-bar-inner {
         position: fixed !important;
         top: 48px !important;
 
         ul {
             padding-bottom: 120px;
             height: auto;
             flex-grow: 1;
         }
 
         .toggle-icon-up {
             display: none;
         }
         .toggle-icon-down {
             display: inline;
         }
 
         .active .toggle-icon-up {
             display: inline;
         }
         .active .toggle-icon-down {
             display: none;
         }
 
         .menu-container .mobile-menu-options {
             display: none;
         }
         .menu-container.active .mobile-menu-options {
             display: block;
         }
 
         .MuiListItem-root {
             display: block;
             width: 100%;
         }
         .mobile-menu-parent {
             width: 100%;
             display: flex;
         }       
         .mobile-menu-parent span {
             font-weight: bold;
         }        
         .mobile-menu-options {
             width: 100%;
             margin-left: 56px;
         }
         .mobile-menu-options ul {
             padding: 5px 0px 0px 20px !important;
         }
         .mobile-menu-options .MuiListSubheader-root {
             text-decoration: uppercase;
             font-weight: bold;
             font-size: 16px;
             padding: 0px;
             position: relative;
         }
         .mobile-menu-options ul li {
             padding: 5px 0px 5px 20px;
         }
     }
 }
 
 /*
  * Menu Bar Styles 
  * Small – Phone (Landscape): 0 – 600px
  */
 @media screen and (max-width: 600px) {
     body.menu-opened .menu-bar .menu-bar-inner {
         top: 104px !important;
     }
     .small-toolbar-left {
         margin-left: -15px;
     }
 }
 
 /* 
  * Misc Styles
  */
 body {
     .inner-content-wrapper {
         width: 100%;
         padding-left: 12px;
         padding-right: 12px;
     }
     .detail-block {
         width: 100%;
     }
 }
 body {
     .MuiBox-root .MuiTypography-root a {
         color: #FFF;
         text-decoration: none;
     }
 }
 body {
     .MuiBox-root .MuiTypography-root a:hover {
         color: #FFF;
         text-decoration: none;
     }
 }
 
 /*
  * Small – Phone (Landscape): 0 – 600px
  */
  @media screen and (max-width: 600px) {
     .component-social-follow-us {
         display: none;
     }
 }
 
 .component-events a.MuiCardActionArea-root:hover {
     text-decoration: none;
 }
 .component-events-buttons-daily-schedule {
     margin-left: 10px !important;
 }

 .component-today-events-card-vs,
 .component-events-card-vs {
    display:block;
    clear: both;
 }
 
 /**
  * Footer
  */
 body .footer-bar {
     padding-left: 16px;
     padding-right: 16px;
     flex-wrap: wrap;
     justify-content: center;
     .footer-bar-inner {
         max-width: 1280px;
         padding: 5px 0px;
         margin: 0 auto;
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
     }
     .footer-bar-inner-school-info {
         flex-wrap: wrap;
         justify-content: center;
     }
 }
 body {
     .react-images__navigation button {
        background: #FFFFFF;
        color: #000000;
        border-radius: 4px;
     }
     .react-multiple-carousel__arrow {
         z-index: 0;
     }
     .react-multi-carousel-track li {
         text-align: center;
     }
     .react-multi-carousel-track li img {
        background: #EEF1F9;
        object-fit: contain;
        height: 400px;
     }
     a.component-results-card-content:hover { //hover for win loss geader
        text-decoration: none;
    }
     a.social svg {
         color: #000;
         font-size: 2.5em;
     }
     a.social:hover{
         opacity: .7;
     }
     .MuiCardHeader-content .MuiBox-root span {
         height: 51px;
     }
     .mobile-menu-options {
         display: none;
     }
 }
 
 /*
 ##Medium – Tablet (Landscape): 768px – 979px
 */
 @media screen and (max-width: 979px) {
     /* CSS */
     .MuiContainer-root .MuiToolbar-root h1 {
         flex-grow: 1;
     }
     body .MuiGrid-container.MuiGrid-spacing-xs-3,
     body.menu-opened .MuiGrid-container.MuiGrid-spacing-xs-3 {
         width: calc(100% + 10px);
         transition: width 195ms;
         .MuiGrid-grid-md-9 {
            max-width: 100%;
         }
         .MuiGrid-grid-md-3 {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            transition: 195ms;
            position: relative;
         }
     }
     body .makeStyles-root-1797 {
         padding-left: 16px;
         padding-right: 16px;
     }
     body .footer-bar {
         .footer-bar-inner .footer-bar-inner-school-info p {
             padding: 0 0px;
         }
         .footer-bar-inner p {
             padding: 0 15px;
         }
         .footer-bar-inner a {
             padding: 0 0px;
         }
         .footer-bar-inner-spacer {
             width: 100%;
         }
     }
     .inner-content-wrapper table {
         width: 100% !important;
     }
 }


