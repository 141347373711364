
body {
  background-color: #FFFFFF !important;
}

#print-roster {
  background: 0;
  margin: 0px;

  #print-button {
    float: right;
  }
}

div {
  display: block;
}

#wrapper {
  margin: auto;
  width: 721px;

  overflow: visible;
  position: relative;
  height: 100%;
}



#header {
  width: 100%;
  float: left;
  position: relative;
  // height: 220px;
  overflow: hidden;

  img {
    float: left;
  }
}

.hidden-header {
  display: none;
}

img {
  border: 0;
}

#header.print #teamname {
  bottom: 15px;
  height: 32px;
  left: 76px;
}

#teamname {
  float: left;
  margin-top: 15px;
}

#wrapper .panel h1 {
  background: #dbdbdb;
  color: #000;
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
  // width: 703px;
  margin-bottom: 0px;
  letter-spacing: 0;
  padding: 1px 0 4px 14px;
}

#header.print #teamname p.name1 {
  background: 0;
  font-size: 25px;
  color: #000;
}

#teamname p.name1 {
  padding: 0 10px;
}

#avatar {
  border-color: #2c4178;
  background-color: #fff;
  color: #000;
  border: 4px solid #5d5d5d;
  float: left;
  height: 150px;
  width: 150px;
  margin: 15px 0 0 15px;
}

#wrapper .panel {
  background: #fff;
  text-align: left;
  // width: 717px;
  float: left;
  margin: 26px 0;
}

#wrapper .panel #content-bottom {
  border-left: 5px solid #dbdbdb;
  border-right: 5px solid #dbdbdb;
  border-bottom: 6px solid #dbdbdb;
  border-top: 0;
  width: 717px;
  padding: 37px 10px 7px 10px;
  float: left;
  margin: 0;
}

#content-bottom {
  float: left;
  margin: 0 10px;
  width: 717px;

  .section-2 {
    background-color: #e6e6e6;
    float: left;
    font-size: 14px;
    font-weight: bold;
    height: 24px;
    margin-top: 10px;
    padding-top: 6px;
    text-align: center;
    width: 100%;
  }
}

.event-table {
  overflow: hidden;
  width: 100%;

  .col-1 {
    width: 78px;
    text-align: center;
    padding-left: 15px;
  }

  .col-2 {
    width: 183px;
    padding-left: 20px;
    text-align: left;
  }

  .col-3 {
    width: 89px;
    padding-left: 20px;
  }

  .col-4 {
    width: 39px;
    padding-left: 20px;
    text-align: center;
  }

  .col-5 {
    width: 86px;
    text-align: center;
  }

  .col-6 {
    width: 79px;
    padding-left: 15px;
  }
}

.event-table table {
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.table-header {
  padding-left: 1em;
}

.event-table #first-header {
  background-color: #e6e6e6;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
  width: 100%;
}

#second-header {
  background-color: #2c4178;
  color: #fff;
  height: 30px;
  width: 100%;
  margin-left: -1px;

  th {
    border-left: 1px solid #fff;
    color: #fff;
    font-size: 14px;
  }
}